import dayjs from "dayjs";
import { STATUS_TASK } from "../constants/constants";
import { message } from "antd";
import * as XLSX from 'xlsx';

export const formatNumber = (number, multiplier) => {
	let result;
	if (number === null || number === undefined) {
		result = ''
	}
	if (number === 0) {
		result = 0
	}
	try {
		result = number.toLocaleString('en-US', {
			minimumFractionDigits: multiplier ?? 0,
			maximumFractionDigits: multiplier ?? 0,
		});
	} catch (error) {
		result = ''
	}
	return result;
}

export const capitalizeFirstLetter = (string) => {
	if (!string) {
		return '';
	}
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const filterOption = (input, option) =>
	(option?.label ?? '').toLowerCase().includes(input.toLowerCase());



export const getDaysInMonth = (month, year) => {
	const daysInMonth = dayjs(`${year}-${month}`).daysInMonth();
	const daysArray = [];
	const firstDayOfWeek = dayjs(`${year}-${month}-01`).day();
	for (let day = 1; day <= daysInMonth; day++) {
		daysArray.push(dayjs(`${year}-${month}-${day}`));
	}
	for (let i = 1; i <= firstDayOfWeek; i++) {
		daysArray.unshift(-1);
	}
	return daysArray;
};

export const mapTaskStatus = (status) => {
	switch (status) {
		case 'pending':
			return 'Pending Assignment';
		case 'processing':
			return 'Processing';
		case 'completed':
			return 'Finished';
		case 'canceled':
			return 'Cancelled';
		case 'waiting':
			return 'Waiting Processing';
	}
}

export function checkUserPermissionSelectStaff(user) {
    // Lặp qua danh sách roles của user để kiểm tra quyền
    for (const role of user.roles) {
        switch (role.id) {
            case "1": // Admin
                return true; // Admin có quyền
            case "2": // Không có quyền
                return false;
            case "3": // Kiểm tra thêm department
                for (const department of user.departments) {
                    if (department.id === "2") {
                        return true; // Có quyền nếu department id = 2
                    }
                }
                return false; // Không có quyền nếu không có department id = 2
            case "4": // Không có quyền
                return false;
            default: // Các role khác
                return false;
        }
    }
    return false; // Mặc định không có quyền nếu không có role
}

export function checkUserIsSupplier(user) {
    if (user?.roles?.length === 1 && user?.roles?.[0]?.id === "5") {
        return true;
    }
    return false;
}

export function checkUserIsFullfillment(user) {
    if (user.roles?.filter(role => role.id === "2").length > 0) {
        return true;
    }
    return false;
}

export function checkUserIsDesigner(user) {
    if (user.roles?.filter(role => role.id === "5" || role.id === "1" || role.id === "2").length > 0) {
        return false;
    }
    return true; 
}

 // Hàm lọc menu theo quyền user
 export function getVisibleMenu(userRoleIds, menuList) {
    return menuList
        .filter(menu => {
            // Kiểm tra menu chính (không có children)
            if (!menu.children) {
                return (
                    menu.permission && 
                    menu.permission.some(permission => userRoleIds.includes(permission))
                );
            }
            // Nếu menu có children, lọc children
            const visibleChildren = menu.children.filter(child =>
                child.permission && child.permission.some(permission => userRoleIds.includes(permission))
            );
            // Cập nhật children đã lọc
            menu.children = visibleChildren;
            // Hiển thị menu nếu có children hoặc có quyền cho menu chính
            return (
                visibleChildren.length > 0 || 
                (menu.permission && menu.permission.some(permission => userRoleIds.includes(permission)))
            );
        });
}

export const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
export const beforeUpload = (file) => {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
	  message.error('You can only upload JPG/PNG file!');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
	  message.error('Image must smaller than 2MB!');
	}
	return isJpgOrPng && isLt2M;
  };
export const getFileExtension = (url) => {
	const extension = url.split('.').pop().split('?')[0];
	return extension.toLowerCase();
};

export function urlToBase64(url) {
    return new Promise((resolve, reject) => {
        // Tải ảnh từ URL dưới dạng Blob
        fetch(url)
            .then((response) => response.blob())  // Chuyển đổi thành Blob
            .then((blob) => {
                // Sử dụng FileReader để chuyển Blob thành Base64
                const reader = new FileReader();
                reader.onloadend = () => {
                    resolve(reader.result); // Trả về Base64 khi quá trình đọc xong
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob); // Đọc Blob dưới dạng Base64
            })
            .catch(reject);  // Xử lý lỗi nếu không tải được ảnh
    });
}

export const copyToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy) // Copy text to clipboard
        .then(() => {
            message.info("Đã sao chép order id");
        })
        .catch((err) => {
        });
};

export const isFullURL = (url) => url.startsWith("http://") || url.startsWith("https://");

export const exportToExcel = (data, fileName) => {
    const columnMapping = [
        { header: "Order Key", key: "order_key" },
        { header: "Order ID", key: "order_id" },
        { header: "SKU", key: "sku" },
        { header: "Quantity", key: "quantity" },
        { header: "Provider Code", key: "provider_code" },
        { header: "Tracking Number", key: "tracking_number" },
        { header: "Is Pass", key: "isPass" },
        { header: "Notes", key: "notes" },
    ];
    // Tạo tiêu đề từ ánh xạ
    const headers = columnMapping.map(col => col.header);

    // Chuyển đổi dữ liệu theo thứ tự ánh xạ
    const formattedData = data.map(item =>
        columnMapping.map(col => item[col.key])
    );

    // Thêm tiêu đề vào dữ liệu
    const worksheetData = [headers, ...formattedData];

    // Tạo worksheet từ dữ liệu đã định dạng
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Tạo workbook và thêm worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Xuất file Excel
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
};