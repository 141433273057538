import { Button, Col, Empty, Form, Image, Input, message, Modal, Row, Select, Space, Spin, Steps, Table, Tabs, Tag, theme, Tooltip } from "antd"
import React, { useEffect, useState } from "react";
import { requiredItem, STATUS_ORDER, STATUS_TASK, TYPE_MODAL } from "../../../constants/constants";
import { BoxPlotFilled, CheckCircleOutlined, CheckCircleTwoTone, ClockCircleFilled, CloseCircleOutlined, EditOutlined, PlusCircleFilled, SearchOutlined, SecurityScanOutlined, ShopOutlined, SyncOutlined, TagOutlined } from "@ant-design/icons";
import { consoleLogUtil } from "../../../utils/consoleLogUtil";
import he from 'he';
import ModalAddEditTask from "./ModalAddEditTask";
import { checkAndUpdateSKUForOrder, convertImageToBase64, createNewTask, getListLogChangeStatus, getListProvider, getListTaskByOrderId, getTrackingStatusByTracknumber, saveLogChangeStatus, updateOrderById, updateShippingInfoByOrderId, updateTaskById, updateTimeAssignSupplier, updateTrackingNumberByOrderId } from "../../../services/apis";
import { useSelector } from "react-redux";
import LoadingBase from "../../../components/LoadingBase";
import { BASE_URL } from "../../../settings/config";
import { checkUserIsDesigner, checkUserIsFullfillment, checkUserIsSupplier, copyToClipboard, filterOption, isFullURL } from "../../../utils/funcHelper";
import ModalEditAddress from "./ModalEditAddress";
import { Images } from "../../../assets";
import ItemUserLog from "../components/ItemUserLog";
import ModalAddNote from "./ModalAddNote";

const ModalOrderDetail = ({ visible, onCancel, onSave, data, title, type, lstSupplier, lstDepartment, lstAction, lstCategories, lstProvider}) => {
    const [form] = Form.useForm();
    const { token } = theme.useToken();
    const [metaData, setMetaData] = useState([]);
    const [visibleModalTask, setVisibleModalTask] = useState(false);
    const [visibleModalAddress, setVisibleModalAddress] = useState(false);
    const [typeModalTask, setTypeModalTask] = useState(TYPE_MODAL.ADD);
    const [taskSelected, setTaskSelected] = useState();
    const [loading, setLoading] = useState(false);
    const [lstTask, setLstTask] = useState([]);
    const [lstLogs, setLstLogs] = useState([]);
    const userReducer = useSelector((state) => state.userReducer?.data);
    const [imageBase64, setImageBase64] = useState({});
    const [isChecking, setIsChecking] = useState(false);
    const [statusOrderExist, setStatusOrderExist] = useState(3);
    const [design_link, setDesignLink] = useState('');
    const [mockup_front, setMockupFront] = useState('');
    const [mockup_back, setMockupBack] = useState('');
    const [old_custom_name, setOldCustomName] = useState('');
    const [old_meta_data, setOldMetaData] = useState('');
    const [shippingInfo, setShippingInfo] = useState();
    const [trackingNumber, setTrackingNumber] = useState();
    const [providerCode, setProviderCode] = useState();
    const [visibleModalNote, setVisibleModalNote] = useState(false);
    const [dataForm, setDataForm] = useState();
    const [dataSteps, setDataSteps] = useState([]);
    const [loadingShipping, setLoadingShipping] = useState(false);

    const columns = [
        {
            title: 'ID',
            key: 'id',
            dataIndex: 'id',
            align: 'center',
            width: 60,
        },
        {
            title: <div style={{ paddingLeft: 20 }}>Department</div>,
            key: 'department_name',
            dataIndex: 'department_name',
            align: 'left',
            width: 150
        },
        {
            title: <div style={{ paddingLeft: 20 }}>Task name</div>,
            key: 'title_task',
            align: 'left',
            dataIndex: 'title_task',
            ellipsis: true,
        },
        {
            title: <div style={{ paddingLeft: 20 }}>Task description</div>,
            key: 'task_content',
            align: 'left',
            dataIndex: 'task_content',
            ellipsis: true,

        },
        {
            title: 'Status',
            key: 'status',
            align: 'center',
            dataIndex: 'status',
            width: 150,
            render: (text, record) => renderStatusTask(record),
        },
        {
            title: 'Assign to',
            width: 200,
            dataIndex: 'assigned_name',
            key: 'assigned_name',
            render: (text, record, index) =>
                <div style={{
                    paddingLeft: 12, paddingRight: 12, wordWrap: "break-word",
                    whiteSpace: "normal",
                }}>
                    {text}
                </div>,
        },
        {
            title: 'Create By',
            width: 200,
            dataIndex: 'creator_fullname',
            key: 'creator_fullname',
            render: (text, record, index) =>
                <div style={{
                    paddingLeft: 12, paddingRight: 12, wordWrap: "break-word",
                    whiteSpace: "normal",
                }}>
                    {text}
                </div>,
        },
        {
            title: 'Create At',
            width: 150,
            dataIndex: 'createAt',
            align: 'center',
            key: 'createAt',
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 60,
            render: (text, record) => (
                <a style={{ color: 'red', padding: 12 }} onClick={(event) => {
                    event.stopPropagation();
                    handleDeleteTask(record);
                }}>Xoá</a>
            )
        }
    ]

    const formStyle = {
        maxWidth: 'none',
        background: '#fff',
        borderRadius: token.borderRadiusLG,
        paddingTop: 12,
        paddingBottom: 24,
        paddingLeft: 0,
        paddingRight: 0,
    };

    const renderStatusTask = (record) => {
        const status = STATUS_TASK.find(item => item.value === record.status);
        return (
            <Tag color={status.value === 'completed' ? 'success' : null}>
                {status?.label}
            </Tag>
        )
    }

    const getDataShippingStatus = async (tracking_number, provider_code) => {
        const params = {
            tracking_number,
            provider_code
        };
        setLoadingShipping(true);
        const res = await getTrackingStatusByTracknumber(params);
        setLoadingShipping(false);
        if (res && res?.status === 1) {
            if (res?.data?.tracking_data?.origin_info && res?.data?.tracking_data?.origin_info?.trackinfo) {
                setDataSteps([...res?.data?.tracking_data?.origin_info?.trackinfo?.map((item, index) => ({
                    ...item,
                    title: item?.checkpoint_delivery_status ?? '',
                    subTitle: item?.checkpoint_date ?? '',
                    description: item?.tracking_detail ?? '',
                    status: 'finish'
                }))
                ]);
            } else {
                setDataSteps([]);
            }
        } else {
            setDataSteps([]);
        }
    }

    const handleDeleteTask = async (record) => {
        try {
            setLoading(true);
            const params = {
                id: record.id,
                order_id: data?.order_id,
                is_activate: 2, // trạng thái xoá
            }
            const res = await updateTaskById(params);
            setLoading(false);
            if (res && res?.status === 1) {
                message.success('Xoá task thành công');
                getDataTask(data?.id);
            } else {
                message.warning('Có lỗi xảy ra, vui lòng thử lại sau');
            }

        } catch (error) {
            consoleLogUtil('handleDeleteTask', error);

        }
    }

    const saveLogChangeStatusProccess = async (id) => {
        try {
            const params = {
                id: id,
                assignSupplierBy: userReducer?.user_info?.id
            }
            const res = await updateTimeAssignSupplier(params);
        } catch (error) {
            consoleLogUtil('saveLogChangeStatusProccess', error);
        }
        
    }

    const handleFinish = (values) => {
        if (type === TYPE_MODAL.ADD) {
            onSave(values);
        } else {
            let tempData = {
                ...data,
                ...values,
            };
            if (values?.status !== data?.status) {
                tempData.newStatus = values?.status;
                tempData.oldStatus = data?.status;
                if (values.status === STATUS_ORDER[2].value) { // Nếu status là 'proccessing' thì gọi API lưu log
                    if (checkUserIsFullfillment(userReducer)) {
                        saveLogChangeStatusProccess(tempData?.id);
                    }
                }
                if (values.status === 'cancelled') { // Nếu status là 'cancelled' thì hiển thị modal để nhập lý do
                    setDataForm(tempData);
                    setVisibleModalNote(true);
                    return;
                } else {
                    setDataForm(null);
                    callApiSaveLogChangeStatus(tempData);
                }
            } else {
                tempData.newStatus = null;
                tempData.oldStatus = data?.status;
                onSave(tempData);
            }

        }
    };

    const callApiSaveLogChangeStatus = async (orderInfo) => {
        const params = {
            order_item_id: orderInfo?.id,
            old_status: orderInfo?.oldStatus,
            new_status: orderInfo?.newStatus,
            updatedBy: userReducer?.user_info?.id
        }
        setLoading(true);
        const res = await saveLogChangeStatus(params);
        setLoading(false);
        if (res && res.status === 1) {
            onSave(orderInfo);
        } else {
            message.error(res?.msg ?? 'Save log failed');
        }

    }

    const handleEditTask = (record) => {
        setTypeModalTask(TYPE_MODAL.EDIT);
        setVisibleModalTask(true);
        setTaskSelected(record);
    }
    const handleAddTask = () => {
        setTypeModalTask(TYPE_MODAL.ADD);
        setVisibleModalTask(true);
    };
    const getDataTask = async (order_id) => {
        try {
            setLoading(true);
            const res = await getListTaskByOrderId({ order_id });
            setLoading(false);
            if (res?.status === 1) {
                setLstTask(res?.data);
            } else {
                setLstTask([]);
            }
        } catch (error) {
            setLoading(false);
            setLstTask([]);
        }

    }
    const getDataLogs = async (order_id) => {
        try {
            const res = await getListLogChangeStatus({ order_id });
            if (res?.status === 1) {
                setLstLogs(res?.data);
            } else {
                setLstLogs([]);
            }
        } catch (error) {
            setLstLogs([]);
        }
    }
    const generateLink = (str) => {
        if (!str) return '';
        return isFullURL(str) ? str : (str.length > 0 ? `https://${str}` : null)
    }
    const handleCheckSKU = async () => {
        try {
            if (!form.getFieldValue('sku')) {
                message.warning('Vui lòng nhập SKU');
                return;
            }
            setIsChecking(true);
            const size = metaData.find(item => item?.name?.toLowerCase() === 'size')?.value;
            let sizeAttribute = size?.length > 0 ? size : '';
            if (sizeAttribute && sizeAttribute.trim() !== "") {
                // Sử dụng regular expression để lấy hai phần đầu tiên trước ký tự "|"
                const match = sizeAttribute.match(/^([^|]+ \| [^|]+)/);
                if (match) {
                    sizeAttribute = match[1].trim();
                }
            }
            const params = {
                sku: form.getFieldValue('sku'),
                size: sizeAttribute ?? null,
                id: data?.id,
            }
            const res = await checkAndUpdateSKUForOrder(params);
            setIsChecking(false);
            if (res?.status === 1) {
                setStatusOrderExist(res?.data?.is_exist?.toString());
                setDesignLink(generateLink(res?.data?.design_link));
                setMockupFront(generateLink(res?.data?.mockup_front));
                setMockupBack(generateLink(res?.data?.mockup_back));
                setOldCustomName(res?.data?.old_custom_name);
                setOldMetaData(res?.data?.old_meta_data);
                message.success('Kiểm tra SKU thành công');
            } else {
                message.warning('SKU không tồn tại');
            }

        } catch (error) {
            setIsChecking(false);
            consoleLogUtil("errr", error);
        }

    }
    const formatMetaData = (metaData) => {
        if (!metaData || metaData?.length === 0) return;
        let temp = metaData.split(';').map(item => {
            const [name, ...valueParts] = item.split(':'); // Lấy phần đầu là name, phần còn lại là value
            const value = valueParts.length > 1 && name.toLowerCase() === 'image'
                ? valueParts.join(':').trim() // Ghép lại toàn bộ phần sau ':' nếu là 'Image'
                : valueParts.join(':').trim(); // Ghép lại toàn bộ nếu không phải là 'Image'

            return { name: name?.trim(), value };
        });

        // Đưa phần tử có name là "Image" xuống dưới cùng
        temp = temp.sort((a, b) => {
            if (a.name.toLowerCase() === 'image') return 1;
            if (b.name.toLowerCase() === 'image') return -1;
            return 0;
        });

        setMetaData([...temp]);
    }
    const handleChangeMetaData = (e) => {
        const value = e.target.value;
        formatMetaData(value);
    }

    const callAPiUpdateShippingInfo = async (payload) => {
        try {
            setLoading(true);
            const res = await updateShippingInfoByOrderId(payload);
            setLoading(false);
            if (res && res?.status === 1) {
                message.success('Cập nhật địa chỉ thành công');
                setShippingInfo({ ...payload });
            } else {
                message.warning(res?.msg ?? 'Lưu thông tin địa chỉ thất bại');
            }
        } catch (error) {
            setLoading(false);
            message.warning('Có lỗi xẩy ra');
        }
    }

    const handleSaveNote = (note) => {
        setVisibleModalNote(false);
        if (dataForm) {
            callApiSaveLogChangeStatus({ ...dataForm, supplier_note: note });
        }
    }

    const handleSaveAddress = (ship_data) => {
        setVisibleModalAddress(false);
        callAPiUpdateShippingInfo(ship_data);
    }
    const handleSaveTask = async (task) => {
        setVisibleModalTask(false);

        const params = {
            ...task,
            order_id: data?.id,
            createBy: userReducer?.user_info?.id,
        }
        setLoading(true);
        let res;
        if (typeModalTask === TYPE_MODAL.ADD) {
            res = await createNewTask(params);
        } else {
            res = await updateTaskById(params);
        }
        setLoading(false);
        if (res?.status === 1) {
            getDataTask(data?.id);
        } else {
            message.warning('Có lỗi xảy ra, vui lòng thử lại sau');
        }
    }
    const handleEditAddress = () => {
        setVisibleModalAddress(true);
    }
    const handleStatusCheckOrderExist = (isExist) => {
        if (isExist === "1") {
            return <CheckCircleTwoTone twoToneColor="#52c41a" />
        } else if (isExist === "2") {
            return <CheckCircleOutlined style={{ color: 'orange' }} />;
        } else if (isExist === "0") {
            return <CloseCircleOutlined style={{ color: 'red' }} />;
        } else {
            return <SyncOutlined spin style={{ color: 'black', width: 10, height: 10 }} />;
        }
    };
    const handleValuesChange = (changedValues, allValues) => {
        if (changedValues.quantity || changedValues.price) {
            const total = (allValues.quantity || 0) * (allValues.price || 0);
            form.setFieldsValue({ total });
        }
    };

    const handleSaveTracking = async () => {
        try {
            if (!trackingNumber || !providerCode) {
                message.warning('Please enter tracking number and select provider');
                return;
            }
            const params = {
                id: data?.id,
                order_key: data?.order_key,
                tracking_numbers: trackingNumber,
                provider_code: providerCode,
                updateBy: userReducer?.user_info?.id,
            }
            setLoading(true);
            const res = await updateTrackingNumberByOrderId(params);
            consoleLogUtil('handleSaveTracking', res);
            setLoading(false);
            if (res && res.status === 1) {
                message.success('Update tracking number success');
                if (res?.data?.tracking_data && res?.data?.tracking_data?.origin_info && res?.data?.tracking_data?.origin_info?.trackinfo) {
                    setDataSteps([...res?.data?.tracking_data?.origin_info?.trackinfo?.map((item, index) => ({
                        ...item,
                        title: item?.checkpoint_delivery_status ?? '',
                        subTitle: item?.checkpoint_date ?? '',
                        description: item?.tracking_detail ?? '',
                        status: 'finish'
                    }))
                    ]);
                } else {
                    setDataSteps([]);
                }
            } else if (res?.status === 7) {
                message.warning(res?.msg ?? 'Tracking number already exists');
            } else {
                message.error(res?.msg ?? 'Update tracking number failed');
                setDataSteps([]);
            }
        } catch (error) {
            consoleLogUtil('handleSaveTracking', error);
            setLoading(false);
        }



    }

    const renderTabLogs = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', overflowX: 'auto', paddingBottom: 30 }}>
                {
                    lstLogs?.length > 0 ? lstLogs.map((item, index) => (
                        <ItemUserLog key={index} index={index} item={item} />
                    )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                }
            </div>
        )
    }

    const renderTabOrderInfo = () => {
        return (
            <Row gutter={16}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <div
                        style={{ width: 130, height: 130, border: '1px solid #f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Image
                            src={data?.image_src ? BASE_URL + data?.image_src : ''}
                            style={{ width: 120, height: 120 }} />

                    </div>
                    <Tag onClick={() => copyToClipboard(data?.order_key)} color="#85cbf8" icon={<TagOutlined />} style={{ width: 120, marginTop: 6 }}>{data?.order_key ?? ''}</Tag>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 12, flex: 1, borderRight: '1px solid #CCC' }}>
                    {
                        checkUserIsSupplier(userReducer) ? 
                        null 
                        : 
                        <p style={{ fontSize: 16, fontWeight: 600 }}>{data?.item_name ?? ''} {handleStatusCheckOrderExist(statusOrderExist)}</p>
                    }
                    
                    <Row gutter={16}>
                        <Col span={10}>
                            <Tag color="#b5b5b5" icon={<ClockCircleFilled />} style={{ width: 150, marginTop: 12 }}>{data?.createAt ?? ''}</Tag>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Form.Item
                                    name={'status'}
                                    style={{ marginTop: 8 }}
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        style={{ width: 200 }}
                                        placeholder="Select status"
                                        options={checkUserIsSupplier(userReducer) ? STATUS_ORDER.filter(item => item.type.includes(3)) : STATUS_ORDER}
                                        filterOption={filterOption}
                                    />
                                </Form.Item>

                            </div>
                            <Form.Item
                                name={'category'}
                                style={{ marginTop: 0 }}
                            >
                                <Input className="cus-input" style={{ height: 30 }} disabled={true} />
                            </Form.Item>
                            <Form.Item
                                name={'categories'}
                                style={{ marginTop: -8 }}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Select category"
                                    options={lstCategories}
                                    filterOption={filterOption}
                                    mode="multiple"
                                    maxTagCount={2}
                                    disabled={checkUserIsSupplier(userReducer)}
                                />
                            </Form.Item>

                        </Col>
                        <Col span={14}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span style={{ fontSize: 15, fontWeight: 800, marginLeft: 32 }}>Link design: {design_link ? <a href={design_link} target="_blank" style={{ textDecorationLine: 'underline' }}>Link</a> : <span style={{ color: 'red', paddingLeft: 12 }}>Chưa có</span>}</span>
                                {
                                    mockup_front?.length > 0 ?
                                        <span style={{ fontSize: 15, fontWeight: 800, marginLeft: 32 }}>Mockup front: <a href={mockup_front} target="_blank" style={{ textDecorationLine: 'underline' }}>Link</a></span> : null
                                }
                                {
                                    mockup_back?.length > 0 ?
                                        <span style={{ fontSize: 15, fontWeight: 800, marginLeft: 32 }}>Mockup back: <a href={mockup_back} target="_blank" style={{ textDecorationLine: 'underline' }}>Link</a></span> : null
                                }
                                {
                                    old_custom_name?.length > 0 ?
                                        <span style={{ fontSize: 15, fontWeight: 800, marginLeft: 32 }}>Old custom name: <a>{old_custom_name}</a></span> : null
                                }
                                {
                                    old_meta_data?.length > 0 ?
                                        <span style={{ fontSize: 15, fontWeight: 800, marginLeft: 32 }}>Old meta data: <a>{old_meta_data}</a></span> : null
                                }
                            </div>
                        </Col>
                    </Row>


                </div>

                <div style={{ width: 290, marginLeft: 12, display: 'flex', flexDirection: 'column' }}>
                    <p style={{ fontSize: 16, fontWeight: 600 }}>General information</p>
                    <Tag color="#009bdc" icon={<ShopOutlined style={{ fontSize: 16 }} />} style={{ width: 150, marginTop: 12 }}><span style={{ fontSize: 15 }}>{data?.store_name}</span></Tag>
                    {
                        checkUserIsSupplier(userReducer) ?
                            null
                            :
                            <span style={{ marginTop: 7, fontSize: 15, fontWeight: 800 }}>
                                Link: <a style={{ fontSize: 15, textDecorationLine: 'underline' }} target="_blank" href={data?.site ? `${data?.site}?p=${data?.product_id}` : ''}>Link sản phẩm</a>
                            </span>
                    }

                    <span><span style={{ marginTop: 7, fontSize: 15, fontWeight: 800 }}>Product ID: </span><span>{data?.product_id ?? ''}</span></span>
                    <span><span style={{ marginTop: 7, fontSize: 15, fontWeight: 800 }}>Tracking numb: </span><span>{data?.tracking_numbers ?? ''}</span></span>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 6 }}>
                        <img src={Images.ic_delivery_truck} style={{ width: 48, height: 48 }} />
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 8 }}>
                            <span style={{ fontSize: 14, fontWeight: 600 }}>{data?.tracking_status?.delivery_status ?? ''}</span>
                            <span style={{ fontSize: 12, fontStyle: 'italic' }}>{data?.tracking_status?.latest_checkpoint_time ?? ''}</span>
                            <span style={{ fontSize: 12, fontStyle: 'italic' }}>{data?.tracking_status?.latest_event ?? ''}</span>
                        </div>
                    </div>
                </div>
            </Row>
        )
    }

    const renderTrackingNumber = () => {
        return (
            <>
                <Row gutter={16} style={{ marginBottom: 30, marginTop: 12 }}>
                    <Col span={8}>
                        <Input
                            placeholder="Tracking number"
                            className="cus-input"
                            style={{ height: 30 }}
                            onChange={(e) => setTrackingNumber(e.target.value)}
                            value={trackingNumber}
                        />
                    </Col>
                    <Col span={8}>
                        <Select
                            showSearch
                            allowClear
                            value={providerCode}
                            placeholder="Select provider"
                            filterOption={filterOption}
                            options={lstProvider}
                            style={{ width: '100%', height: 30 }}
                            onSelect={(value) => setProviderCode(value)}
                        />
                    </Col>
                    <Col span={4}>
                        <Button
                            type="primary"
                            style={{ height: 30 }}
                            onClick={handleSaveTracking}
                        >
                            Save tracking
                        </Button>
                    </Col>
                </Row>
                {
                    loadingShipping ? 
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Spin />
                        <p style={{ paddingLeft: 10 }}>Vui lòng đợi...</p>
                    </div> : (
                        dataSteps.length > 0 ?
                        <>
                            <p style={{fontWeight: 600, marginBottom: 15}}>Shipping information:</p>
                            <div style={{ overflowX: 'auto', paddingBottom: 10 }}>
                                <Steps
                                    current={1}
                                    progressDot
                                    responsive={true}
                                    items={dataSteps}
                                />
                            </div>
                        </>
                        : <span style={{ color: 'red', textAlign: 'center', alignItems: 'center' }}><SecurityScanOutlined size='' /> No shipping data</span>
                    )
                    
                }


            </>

        )
    }

    const listTab = !checkUserIsDesigner(userReducer) ? [
        {
            label: 'Order Info',
            key: '1',
            children: renderTabOrderInfo(),
        },
        {
            label: 'Add tracking',
            key: '2',
            children: renderTrackingNumber(),
        },
        {
            label: 'Logs change status',
            key: '3',
            children: renderTabLogs(),
        },
    ] :
        [
            {
                label: 'Order Info',
                key: '1',
                children: renderTabOrderInfo(),
            },
        ]

    useEffect(() => {
        // Tải Base64 cho mỗi ảnh khi `metaData` thay đổi
        metaData.forEach((item) => {
            if (item?.name.toLowerCase() === 'image' && item?.value) {
                convertImageToBase64({ image_url: item?.value }).then((res) => {
                    setImageBase64((prev) => ({
                        ...prev,
                        [item?.name]: res.base64_image,
                    }));
                });
            }
        });
    }, [metaData]);

    useEffect(() => {
        if (type === TYPE_MODAL.EDIT) {
            setMetaData([]);
            setImageBase64({});
            setDataSteps([]);
            if (data?.tracking_numbers?.length > 0 && data?.provider_code?.length > 0) {
                getDataShippingStatus(data?.tracking_numbers, data?.provider_code);
            }
            setStatusOrderExist(data?.is_exist);
            setDesignLink(generateLink(data?.design_link));
            setMockupFront(generateLink(data?.mockup_front));
            setMockupBack(generateLink(data?.mockup_back));
            setOldCustomName(data?.old_custom_name);
            setOldMetaData(data?.old_meta_data);
            const dataShipping = {
                id: data?.id,
                customer_address: data?.customer_address ?? '',
                shipping_info: data?.shipping_info
            }
            setShippingInfo({ ...dataShipping });
            setTrackingNumber(data?.tracking_numbers);
            setProviderCode(data?.provider_code);
            setTrackingNumber(data?.tracking_numbers);
            setLoading(true);
            Promise.all([
                getDataTask(data?.id),
                getDataLogs(data?.id),
            ]).finally(() => {
                setLoading(false);
            });
            form.setFieldsValue({
                order_id: data?.order_id,
                sku: data?.sku,
                total: data?.total,
                quantity: data?.quantity,
                price: data?.price,
                method_shipping: data?.method_shipping,
                meta_data: data?.meta_data,
                status: data?.status,
                suppiler_id: data?.suppiler_id,
                category: data?.category ?? '',
            });
            if (data?.meta_data && data?.meta_data.length > 0) {
                formatMetaData(data?.meta_data);
            }
        } else {
            form.resetFields();
            setLstTask([]);
            setMetaData([]);
            setImageBase64({});
        }
    }, [data, type]);
    useEffect(() => {
        if (lstCategories && lstCategories.length > 0 && form && data?.categories && data?.categories.length > 0) {
            form.setFieldsValue({
                categories: data?.categories?.map(item => item?.id),
            });
        } else {
            form.setFieldsValue({
                categories: [],
            });
        }
    }, [lstCategories, data])

    useEffect(() => {
        if (shippingInfo?.id && form) {
            form.setFieldsValue({
                customer_name: shippingInfo?.shipping_info?.first_name + " " + shippingInfo?.shipping_info?.last_name,
                shipping_address: shippingInfo?.customer_address,
                customer_phone: shippingInfo?.shipping_info?.phone,
            });
        }
    }, [shippingInfo])

    return (
        <Modal
            title={title || 'Order details'}
            open={visible}
            onCancel={onCancel}
            width={'80%'}
            footer={null}
        >
            <Form form={form} layout="vertical" name="order-detail" style={formStyle} onFinish={handleFinish} onValuesChange={handleValuesChange}>

                <Tabs
                    defaultActiveKey="1"
                    type="card"
                    size={'middle'}
                    items={listTab}
                    
                />

                <Row gutter={16} style={{ marginTop: 24 }}>
                    <div style={{ flex: 1, flexDirection: 'column' }} >
                        <p style={{ textAlign: 'left', fontWeight: 700, marginBottom: 8 }}>Order Info</p>
                        <Row gutter={16}>
                            <Col span={10}>
                                <Form.Item
                                    name={'order_id'}
                                    rules={requiredItem}
                                    className="custom-form-item"
                                    label={<span className={'cus-input-label'}>Origin ID</span>}
                                >
                                    <Input className="cus-input" style={{ height: 30 }} disabled={checkUserIsSupplier(userReducer)} />
                                </Form.Item>
                            </Col>
                            <Col span={14}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Form.Item
                                        name={'sku'}
                                        className="custom-form-item"
                                        label={<span className={'cus-input-label'}>SKU</span>}
                                        style={{ flex: 1, marginRight: 8 }}
                                    >
                                        <Input className="cus-input" style={{ height: 30 }} disabled={checkUserIsSupplier(userReducer)} />
                                    </Form.Item>
                                    {
                                        checkUserIsSupplier(userReducer) ?
                                            null
                                            :
                                            <Button
                                                type="primary"
                                                shape="circle"
                                                icon={<SearchOutlined />}
                                                loading={isChecking}
                                                style={{ width: 30, height: 30 }}
                                                onClick={handleCheckSKU}
                                            />
                                    }

                                </div>

                            </Col>
                        </Row>
                        <Row gutter={16} style={{ marginTop: -10 }}>
                            {
                                checkUserIsSupplier(userReducer) ?
                                    null
                                    :
                                    <Col span={10}>
                                        <Form.Item
                                            name={'total'}
                                            className="custom-form-item"
                                            label={<span className={'cus-input-label'}>Total</span>}
                                        >
                                            <Input className="cus-input" disabled={true} style={{ height: 30 }} />
                                        </Form.Item>
                                    </Col>
                            }

                            <Col span={5}>
                                <Form.Item
                                    name={'quantity'}
                                    className="custom-form-item"
                                    label={<span className={'cus-input-label'}>Qty</span>}
                                >
                                    <Input type="number" className="cus-input" style={{ height: 30 }} disabled={checkUserIsSupplier} />
                                </Form.Item>
                            </Col>
                            {
                                checkUserIsSupplier(userReducer) ?
                                    null
                                    :
                                    <Col span={6}>
                                        <Form.Item
                                            name={'price'}
                                            className="custom-form-item"
                                            label={<span className={'cus-input-label'}>Price</span>}
                                        >
                                            <Input type="number" className="cus-input" style={{ height: 30 }} />
                                        </Form.Item>
                                    </Col>
                            }

                        </Row>
                        {
                            data?.billing_info ?
                                <>
                                    <p style={{ textAlign: 'left', fontWeight: 700, marginBottom: 8, marginTop: -10 }}>Billing Info</p>
                                    <p style={{ textAlign: 'left', fontWeight: 500, marginBottom: 8, marginTop: 0 }}>{`${data?.billing_info?.first_name ?? ''} ${data?.billing_info?.last_name ?? ''}, ${data?.billing_info?.address_1 ?? ''}, ${data?.billing_info?.city ?? ''}`}</p>
                                    <p style={{ textAlign: 'left', fontWeight: 500, marginBottom: 8, marginTop: 0 }}>{data?.billing_info?.email ?? ''}</p>
                                </> : null
                        }

                        <Form.Item
                            name={'suppiler_id'}
                            className="custom-form-item"
                            label={<span className={'cus-input-label'}>Supplier</span>}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Select supplier"
                                filterOption={filterOption}
                                options={lstSupplier}
                                disabled={checkUserIsSupplier(userReducer)}
                            />
                        </Form.Item>
                    </div>
                    <div style={{ flex: 1, flexDirection: 'column', marginLeft: 32, marginRight: 32 }} >
                        <p style={{ textAlign: 'left', fontWeight: 700, marginBottom: 8 }}>Meta data</p>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name={'meta_data'}
                                    className="custom-form-item"
                                    label={<span className={'cus-input-label'}>Meta full</span>}
                                >
                                    <Input.TextArea className="cus-input" onChange={handleChangeMetaData} autoSize={{ minRows: 2 }} disabled={checkUserIsSupplier(userReducer)} />
                                </Form.Item>
                            </Col>

                        </Row>
                        <div style={{ marginLeft: 8 }}>
                            {
                                metaData && metaData.length > 0 ?
                                    metaData.map((item, index) => (
                                        item?.name.toLowerCase() === 'size' ?
                                            <Row gutter={16} key={index}>
                                                <span><strong>{item?.name}:</strong> {he.decode(item?.value)}</span>
                                            </Row> :
                                            item?.name.toLowerCase() !== 'image' ?
                                                <Row gutter={16} key={index}>
                                                    <span><strong>{item?.name}:</strong> {item?.value}</span>
                                                </Row> :
                                                (
                                                    <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: -8 }}>
                                                        <span><strong>{item?.name}:&nbsp;&nbsp;</strong></span>
                                                        {imageBase64[item?.name] ? (
                                                            <Image
                                                                src={`data:image/png;base64,${imageBase64[item?.name]}`}
                                                                style={{ width: 40, height: 40, marginTop: 8 }}
                                                            />
                                                        ) : (
                                                            <span>Loading...</span> // Hiển thị khi Base64 chưa có
                                                        )}
                                                    </div>
                                                )
                                    )) : null
                            }
                        </div>

                    </div>
                    <div style={{ flex: 1, flexDirection: 'column' }} >
                        <Space>
                            <p style={{ textAlign: 'left', fontWeight: 700, marginBottom: 8 }}>Customer Info</p>
                            {
                                checkUserIsSupplier(userReducer)
                                    ?
                                    null
                                    :
                                    <Button type="link" icon={<EditOutlined />} onClick={handleEditAddress} />
                            }
                        </Space>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name={'customer_name'}
                                    className="custom-form-item"
                                    label={<span className={'cus-input-label'}>Customer name</span>}
                                >
                                    <Input className="cus-input" style={{ height: 30 }} disabled={true} />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row gutter={16} style={{ marginTop: -10 }}>
                            <Col span={16}>
                                <Form.Item
                                    name={'customer_phone'}
                                    className="custom-form-item"
                                    label={<span className={'cus-input-label'}>Phone</span>}
                                >
                                    <Input className="cus-input" style={{ height: 30 }} disabled={true} />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row gutter={8} style={{ marginTop: -10 }}>
                            <Col span={24}>
                                <Form.Item
                                    name={'shipping_address'}
                                    className="custom-form-item"
                                    label={<span className={'cus-input-label'}>Shipping address</span>}
                                >
                                    <Input className="cus-input" style={{ height: 30 }} disabled={true} />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row gutter={16} style={{ marginTop: -10 }}>
                            <Col span={24}>
                                <Form.Item
                                    name={'method_shipping'}
                                    className="custom-form-item"
                                    label={<span className={'cus-input-label'}>Method shipping</span>}
                                >
                                    <Input className="cus-input" style={{ height: 30 }} disabled={true} />
                                </Form.Item>
                            </Col>

                        </Row>
                    </div>
                </Row>

                {
                    type === TYPE_MODAL.EDIT && !checkUserIsSupplier(userReducer) ?
                        <>
                            <Button icon={<PlusCircleFilled />} type="default" style={{ marginTop: 12 }} onClick={handleAddTask}>Create task</Button>
                            <Table
                                columns={columns}
                                className="custom-table-task"
                                style={{ marginTop: 12 }}
                                dataSource={lstTask}
                                rowKey={record => record.id}
                                onRow={(record, rowIndex) => {
                                    return {
                                        onClick: event => {
                                            handleEditTask(record);
                                        }, // click row
                                        onMouseEnter: event => {
                                            event.currentTarget.style.cursor = 'pointer';
                                        }, // hover row
                                    };
                                }}
                                scroll={{ x: 1300 }}
                            />
                        </>
                        :
                        null

                }

                <Row gutter={16} justify={'end'}>
                    <Space>
                        <Button onClick={onCancel}>
                            Hủy
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Lưu
                        </Button>
                    </Space>
                </Row>
            </Form>
            <ModalAddEditTask
                visible={visibleModalTask}
                onCancel={() => setVisibleModalTask(false)}
                onSave={handleSaveTask}
                data={taskSelected}
                title={'Create new task'}
                type={typeModalTask}
                dataDepartment={lstDepartment}
                lstAction={lstAction}
            />
            <ModalEditAddress
                visible={visibleModalAddress}
                onCancel={() => setVisibleModalAddress(false)}
                onSave={handleSaveAddress}
                data={shippingInfo}
            />
            <ModalAddNote
                visible={visibleModalNote}
                onCancel={() => setVisibleModalNote(false)}
                onSave={handleSaveNote}
            />
            <LoadingBase loading={loading} />
        </Modal>
    )
}
export default React.memo(ModalOrderDetail);

