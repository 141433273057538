import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space, theme } from "antd"
import React, { useEffect, useState } from "react";
import { PASSWORD_DEFAULT, requiredItem, ROLE_PERMISSION, TYPE_MODAL } from "../../../constants/constants";
import { consoleLogUtil } from "../../../utils/consoleLogUtil";

const ModalAddAndEdit = ({ visible, onCancel, onSave, data, title, type, lstDepartment, lstRoles, lstSupplier }) => {
    const [form] = Form.useForm();
    const { token } = theme.useToken();
    const [isSupplier, setIsSupplier] = useState(false);
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const handleFinish = (values) => {
        console.log(values);
        if (type === TYPE_MODAL.ADD) {
            onSave({
                ...values,
                suppliers: values.supplier ? [values.supplier] : []
            });
        } else {
            let tempData = { ...values };
            if (values.password === PASSWORD_DEFAULT) {
                delete tempData.password;
            }
            tempData.id = data.id;
            tempData.suppliers = values.supplier ? [values.supplier] : [];
            onSave(tempData);
        }
    };

    const handleChangeRoleSupplier = (values) => {
        consoleLogUtil(values);
        if (values.includes('5')) { //Nhóm quyền nhà cung cấp
            setIsSupplier(true);
        } else {
            setIsSupplier(false);
        }
    }
    const handleChangeDepartment = (values) => {
        consoleLogUtil(values);
        if (values.includes('4')) { //Phòng ban là là nhà cung cấp
            setIsSupplier(true);
        } else {
            setIsSupplier(false);
        }
        form.setFieldsValue({
            supplier: []
        });
    }

    useEffect(() => {
        if (type === TYPE_MODAL.EDIT) {
            form.setFieldsValue({
                username: data.username,
                fullname: data.fullname,
                password: PASSWORD_DEFAULT,
                departments: data.departments.map(item => item.id),
                roles: data.roles.map(item => item.id),
            });
            if (data?.roles?.length === 1 && data.roles.map(item => item.id).includes(ROLE_PERMISSION.SUPPLIER)) {
                setIsSupplier(true);
                form.setFieldsValue({
                    supplier: data?.suppliers?.map(item => item.id)
                });
            } else {
                setIsSupplier(false);
            }
        } else {
            form.resetFields();
        }
    }, [data, type, visible]);

    return (
        <Modal
            title={title}
            open={visible}
            onCancel={onCancel}
            width={'80%'}
            footer={null}
        >
            <Form form={form} layout="vertical" name="add-edit-account" style={formStyle} onFinish={handleFinish}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                            label="Tên tài khoản"
                            name="username"
                            rules={requiredItem}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Họ và tên"
                            name="fullname"
                            rules={requiredItem}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Mật khẩu"
                            name="password"
                            rules={requiredItem}
                        >
                            <Input type="password" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Phòng ban"
                            name="departments"
                            rules={requiredItem}
                        >
                            <Select
                                mode="multiple"
                                style={{ width: '100%', height: 40 }}
                                placeholder="Chọn phòng ban"
                                maxTagCount={'responsive'}
                                options={lstDepartment}
                                onChange={handleChangeDepartment}
                            />
                        </Form.Item>
                    </Col>
                    {
                        isSupplier ?
                            <Col span={12}>
                                <Form.Item
                                    label="Nhà cung cấp"
                                    name="supplier"
                                    rules={requiredItem}
                                >
                                    <Select
                                        style={{ width: '100%', height: 40 }}
                                        placeholder="Chọn nhà cung cấp"
                                        maxTagCount={'responsive'}
                                        options={lstSupplier}
                                        
                                    />
                                </Form.Item>
                            </Col>
                            :
                            null
                    }

                    <Col span={12}>
                        <Form.Item
                            label="Quyền"
                            name="roles"
                            rules={requiredItem}
                        >
                            <Checkbox.Group options={lstRoles} onChange={handleChangeRoleSupplier}/>
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={16} justify={'end'}>
                    <Space>
                        <Button onClick={onCancel}>
                            Hủy
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Lưu
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
    )
}
export default React.memo(ModalAddAndEdit);