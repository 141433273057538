import { Button, Col, Form, Input, Row, Space, theme, Table, message, Tag, Select, DatePicker, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { createNewOrder, getListDepartment, getListOrders, getListShops, getListSuppiler, updateOrderById, updateForMutilOrder, exportOrderBySupplier, exportOrderBySelected, getListTypeAction, getOrderDetailById, getListCategories, getListProvider } from '../../services/apis';
import { DATE_FORMAT, requiredItem, STATUS_ORDER, STATUS_SHIPPING, statusOrderMap, TYPE_EXPORT, TYPE_MODAL, TYPE_TIME } from '../../constants/constants';
import { CheckCircleOutlined, CheckCircleTwoTone, CloseCircleOutlined, DoubleRightOutlined, ExportOutlined, SearchOutlined, SendOutlined, ShopOutlined, SyncOutlined, TagOutlined } from '@ant-design/icons';
import { filterOption } from '../../utils/funcHelper';
import dayjs from 'dayjs';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import ModalOrderDetail from './modal/ModalOrderDetail';
import ModalAddOrder from './modal/ModalAddOrder';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../settings/config';
import FooterTable from '../../components/FooterTable';
import ModalSelectSupplier from './modal/ModalSelectSupplier';
import ModalSelectedOrder from './modal/ModalSelectedOrder';

const ListExportSupplierPage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();

    const initialValues = {
        keyword: '',
        status: null,
        typeTime: 2,
        date: [dayjs().startOf('day'), dayjs().endOf('day')]
    };

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalAddOrder, setShowModalAddOrder] = useState(false);
    const [itemSelected, setItemSelected] = useState();
    const [typeModal, setTypeModal] = useState();
    const [titleModal, setTitleModal] = useState('');
    const [dataSuppliers, setDataSuppliers] = useState([]);
    const [dataCategories, setDataCategories] = useState([]);
    const [dataDepartments, setDataDepartments] = useState([]);
    const [dataShops, setDataShops] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageSize, setCurrentPageSize] = useState(20);
    const [totalPage, setTotalPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const userReducer = useSelector((state) => state?.userReducer?.data);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [showModalSupplier, setShowModalSupplier] = useState(false);
    const [dataAction, setDataAction] = useState([]);
    const [showModalSelected, setShowModalSelected] = useState(false);
    const [lstProvider, setLstProvider] = useState([]);
    const { RangePicker } = DatePicker;
    const columns = [
        {
            title: 'Order ID',
            dataIndex: 'order_key',
            key: 'order_key',
            width: 100,
            align: 'center',
            render: (text, record, index) => <div>
                <a style={{ paddingLeft: 8, paddingRight: 8 }}>{text}</a>
            </div>,
        },
        {
            title: 'Origin ID',
            dataIndex: 'order_id',
            key: 'order_id',
            width: 100,
            align: 'center',
            render: (text, record, index) => <div>
                <a style={{ textDecorationLine: 'underline' }}>{text}</a>
            </div>,
        },
        {
            title: 'Image',
            dataIndex: 'image_src',
            key: 'image_src',
            align: 'center',
            width: 90,
            render: (text, record, index) => <div style={{ padding: 6 }}><img src={BASE_URL + text} style={{ width: 80, height: 80 }} /></div>,
        },
        {
            title: <div style={{ paddingLeft: 20 }}>Product info</div>,
            align: 'left',
            width: 400,
            render: (text, record, index) => <div>
                <div style={{
                    paddingLeft: 12, paddingRight: 24, wordWrap: "break-word",
                    whiteSpace: "normal",
                }}>
                    <TagOutlined /> <strong>{record?.item_name ?? ''}</strong><span style={{ paddingLeft: 6 }}>
                        {calculatorCheckExist(record?.is_exist)}
                    </span></div>
                <span style={{ paddingLeft: 12, color: 'orange', textDecorationLine: 'underline' }}>{record?.category ?? ''}</span>
                {
                    record?.categories?.length > 0 ? 
                    <div style={{ paddingLeft: 12, paddingRight: 12, paddingBottom: 8}}>
                        {
                            record?.categories?.map((item, index) => <Tag key={index} >{item?.name}</Tag>)
                        }
                    </div> : null
                }
            </div>,
        },
        {
            title: 'Order status',
            align: 'center',
            width: 150,
            render: (text, record, index) =>
                calculatorStatusOrder(record)

        },
        {
            title: 'Shipping status',
            align: 'center',
            width: 150,
            dataIndex: 'delivery_status',
            key: 'delivery_status',
        },
        {
            title: 'Number tracking',
            align: 'center',
            width: 200,
            render: (text, record, index) => <div style={{
                paddingLeft: 12, paddingRight: 12
            }}>
                {record?.tracking_numbers ?? ''}
            </div>,
        },
        {
            title: 'Store',
            dataIndex: 'store_name',
            key: 'store_name',
            align: 'center',
            width: 150,
            render: (text, record, index) => <div>
                <Tag icon={<ShopOutlined />} color="#3b5999">
                    {text}
                </Tag>
            </div>,
        },
        {
            title: 'Supplier',
            align: 'center',
            width: 200,
            render: (text, record, index) => <div style={{
                paddingLeft: 12, paddingRight: 12
            }}>
                {record?.suppiler_name ?? ''}
            </div>,
        },
        {
            title: 'Export At',
            dataIndex: 'exportAt',
            key: 'exportAt',
            width: 100,
            align: 'center',
            render: (text, record, index) => <div>
                <div>{text ? dayjs(text).format('HH:mm:ss') : ''}</div>
                <div>{text ? dayjs(text).format(DATE_FORMAT.DD_MM_YYYY_FORMAT) : ''}</div>
            </div>,
        },
        {
            title: 'Create At',
            dataIndex: 'createAt',
            key: 'createAt',
            width: 100,
            align: 'center',
            render: (text, record, index) => <div>
                <div>{dayjs(text).format('HH:mm:ss')}</div>
                <div>{dayjs(text).format(DATE_FORMAT.DD_MM_YYYY_FORMAT)}</div>
            </div>,
        },
    ];

    const formStyle = {
        maxWidth: 'none',
        background: 'white',
        borderRadius: token.borderRadiusLG,
        paddingLeft: 0,
        paddingRight: 0,
        marginBottom: 12,
    };
    const calculatorCheckExist = React.useMemo(() => {
        return (isExist) => {
            if (isExist === "1") {
                return <CheckCircleTwoTone twoToneColor="#52c41a" />
            } else if (isExist === "2") {
                return <CheckCircleOutlined style={{ color: 'orange' }} />;
            } else if (isExist === "0") {
                return <CloseCircleOutlined style={{ color: 'red' }} />;
            } else {
                return <SyncOutlined spin style={{ color: 'black', width: 10, height: 10 }} />;
            }
        };
    }, []);

    const calculatorStatusOrder = (record) => {
        const status = statusOrderMap[record?.status] || statusOrderMap.pending;
        return <Tag icon={status.icon} color={status.color}>{status.text}</Tag>;
    }


    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRows(newSelectedRows);
            setSelectedRowKeys(newSelectedRowKeys);
        },
        preserveSelectedRowKeys: true,
    };

    const getDataDepartment = async () => {
        try {
            const params = {
                keyword: '',
            }
            const res = await getListDepartment(params);
            if (res && res.status === 1) {
                setDataDepartments([...res?.data]);
            } else {
                setDataDepartments([]);
            }
        } catch (error) {
            console.log('error', error);
            setDataDepartments([]);
        }


    }

    const getDataOrderDetail = async (id) => {
        const params = {
            id: id
        };
        setLoading(true);
        const res = await getOrderDetailById(params);
        setLoading(false);
        consoleLogUtil("getOrderDetailById", res);
        if (res && res?.status === 1) {
            setItemSelected({ ...res?.data });
            setTypeModal(TYPE_MODAL.EDIT);
            setShowModal(true);
            setTitleModal('Sửa đơn hàng');
        } else {
            message.warning(res?.msg ?? 'Không lấy được thông tin order');
        }
    }

    const getDataProvider = async () => {
        try {
            const res = await getListProvider({ keyword: '' });
            if (res && res?.status === 1) {
                setLstProvider([...res?.data?.data?.map(item => ({ label: item?.name, value: item?.code }))]);
            } else {
                setLstProvider([]);
            }
        } catch (error) {
            setLstProvider([]);
        }
    }

    const getDataSuppiler = async () => {
        try {
            const params = {
                keyword: '',
            }
            const res = await getListSuppiler(params);
            if (res && res.status === 1) {
                setDataSuppliers([...res?.data]);
            } else {
                setDataSuppliers([]);
            }
        } catch (error) {
            setDataSuppliers([]);
        }
    }

    const getDataCategories = async () => {
        try {
            const params = {
                keyword: '',
            }
            const res = await getListCategories(params);
            if (res && res.status === 1) {
                setDataCategories([...res?.data?.data?.map(item => ({ 
                    ...item, 
                    id: parseInt(item?.id), 
                    value: parseInt(item?.id), 
                    label: item?.name 
                }))]);
            } else {
                setDataCategories([]);
            }
        } catch (error) {
            setDataCategories([]);
        }
    }

    const handleEditOrder = (record) => {
        getDataOrderDetail(record?.id);
    }
    const handleExportSelected = () => {
        callApiExportOrderSelected(true);
    }

    const handleExportDraft = () => {
        callApiExportOrderSelected(false);
    }

    const callApiExportOrderSelected = async (isUpdateTime) => {
        try {
            const params = {
                ids: selectedRows.map(item => item?.id),
                isUpdateTime: isUpdateTime
            }
            setLoading(true);
            const res = await exportOrderBySelected(params);
            setLoading(false);
            if (res && res.status === 1) {
                message.success('Xuất excel thành công');
                window.open(res?.data?.download_link);
            } else {
                message.error(res?.msg ?? 'Xuất excel thất bại');
            }
        } catch (error) {
            setLoading(false);
            message.error('Xuất excel thất bại');
        }
    }
    const handleExportAll = async () => {
        try {
            const params = {
                suppiler_id: form.getFieldValue('suppiler_id') ?? null,
                startTime: dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT),
                endTime: dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT),
                store_name: form.getFieldValue('store_name') ?? null,
                status: form.getFieldValue('status') ?? null,
                typeTime: form.getFieldValue('typeTime') ?? 2,
                isHaveExportAt: form.getFieldValue('isHaveExportAt') ? (form.getFieldValue('isHaveExportAt') === 1 ? true : false) : null
            }
            setLoading(true);
            const res = await exportOrderBySupplier(params);
            setLoading(false);
            if (res && res.status === 1) {
                message.success('Xuất excel thành công');
                window.open(res?.data?.download_link);
            } else {
                message.error(res?.msg ?? 'Xuất excel thất bại');
            }
        } catch (error) {
            setLoading(false);
            message.error('Xuất excel thất bại');
        }

    }
    const handleSaveSupplier = async (data) => {
        try {
            setShowModalSupplier(false);
            consoleLogUtil('data', data);
            if (!data?.status && !data?.supplier_id) {
                message.error('Vui lòng chọn ít nhất 1 trạng thái hoặc 1 nhà cung cấp');
                return;
            }
            const params = {
                suppiler_id: data?.supplier_id ?? null,
                status: data?.status ?? null,
                order_ids: selectedRows.map(item => item?.id),
                isFullfill: true,
                user_id: userReducer?.user_info?.id
            }
            setLoading(true);
            const res = await updateForMutilOrder(params);
            setLoading(false);
            if (res && res.status === 1) {
                setSelectedRowKeys([]);
                setSelectedRows([]);
                message.success('Cập nhật thông tin thành công');
                getDataOrders(currentPage, dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true)
            } else {
                message.error('Cập nhật thông tin thất bại');
            }
        } catch (error) {
            setLoading(false);
            console.log('error', error);
            message.error('Cập nhật NCC thất bại');
        }

    }

    const handleRemoveSelected = (id) => {
        const newSelectedRows = selectedRows.filter(item => item?.id !== id);
        const newSelectedRowKeys = selectedRowKeys.filter(item => item !== id);
        setSelectedRows([...newSelectedRows]);
        setSelectedRowKeys([...newSelectedRowKeys]);
    }
    
    const handleClearSelected = () => {
        setSelectedRows([]);
        setSelectedRowKeys([]);
    }

    const getDataShops = async () => {
        try {
            const res = await getListShops();
            if (res && res.status === 1) {
                setDataShops([...res?.data?.data]);
            } else {
                setDataShops([]);
            }
        } catch (error) {
            setDataShops([]);
        }
    }

    const getDataOrders = async (page, startTime, endTime, showLoading, pageSize) => {
        try {
            const params = {
                keyword: form.getFieldValue('keyword') ?? '',
                status: form.getFieldValue('status') ?? null,
                status_list: STATUS_ORDER.filter(status => status.type.includes(2)).map(item => item.value),
                delivery_status: form.getFieldValue('delivery_status') ?? null,
                store_name: form.getFieldValue('store_name') ?? '',
                suppiler_id: form.getFieldValue('suppiler_id') ?? null,
                typeTime: form.getFieldValue('typeTime') ?? 2,
                startTime: startTime,
                endTime: endTime,
                limit: pageSize ?? currentPageSize,
                currentPage: page,
                isHaveExportAt: form.getFieldValue('isHaveExportAt') ? (form.getFieldValue('isHaveExportAt') === 1 ? true : false) : null
            }
            if (showLoading) setLoading(true);
            const res = await getListOrders(params);
            if (showLoading) setLoading(false);
            consoleLogUtil('getListOrders', res);
            if (res && res.status === 1) {
                setData([...res?.data?.data]);
                setTotalRecords(res?.data?.totalRows);
                setTotalPage(res?.data?.totalPages);
            } else {
                setData([]);
                setTotalRecords(0);
                setTotalPage(1);
            }
        } catch (error) {
            setLoading(false);
            console.log('error', error);
        }


    }
    const handlePrev = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page - 1);
        getDataOrders(page - 1, dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true)
    }
    const handleOnSelectPageSize = (size) => {
        setCurrentPageSize(size);
        getDataOrders(currentPage, dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true, size)
    }
    const handleOnSelect = (value) => {
        setCurrentPage(value);
        getDataOrders(value, dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true)
    }
    const handleNext = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page + 1);
        getDataOrders(page + 1, dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true)
    }
    const onFinish = async (dataForm) => {
        handleClearSelected();
        setCurrentPage(1);
        getDataOrders(1, dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true);
        getDataCategories();
    };
    const callApiCreateNewOrder = async (orderInfo) => {
        // call api create new order
        try {
            const params = {
                ...orderInfo,
                updateBy: userReducer?.user_info?.id
            }
            setLoading(true);
            const res = await createNewOrder(params);
            setLoading(false);
            if (res && res.status === 1) {
                message.success('Thêm mới đơn hàng thành công');
                getDataOrders(currentPage, dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true)
            } else {
                message.error('Thêm mới đơn hàng thất bại');
            }
        } catch (error) {
            console.log('error', error);
            setLoading(false);
        }
    }
    const callApiUpdateOrder = async (orderInfo) => {
        // call api update order
        try {
            setLoading(true);
            const res = await updateOrderById(orderInfo);
            setLoading(false);
            if (res && res.status === 1) {
                message.success('Cập nhật đơn hàng thành công');
                getDataOrders(currentPage, dayjs(form.getFieldValue("date")[0]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs(form.getFieldValue("date")[1]).format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true)
            } else {
                message.error(res?.msg ?? 'Cập nhật đơn hàng thất bại');
            }
        } catch (error) {
            console.log('error', error);
            setLoading(false);
        }
    }

    const handleSaveOrder = (orderInfo) => {
        setShowModal(false);
        if (typeModal === TYPE_MODAL.ADD) {
            setShowModalAddOrder(false);
            consoleLogUtil('orderInfo', orderInfo);
            callApiCreateNewOrder(orderInfo);
        } else {
            callApiUpdateOrder(orderInfo);
        }
    }
    const getDataTypeAction = async () => {
        try {
            const res = await getListTypeAction();
            if (res && res.status === 1) {
                setDataAction([...res?.data?.data?.map(item => ({ ...item, value: item?.id, label: item?.name }))]);
            } else {
                setDataAction([]);
            }
        } catch (error) {
            console.log('error', error);
        }
    }
    useEffect(() => {
        Promise.all([getDataDepartment(), getDataSuppiler(), getDataShops(), getDataTypeAction(), getDataCategories(), getDataProvider()]);
    }, [])

    useEffect(() => {
        if (form) {
            form.setFieldsValue(initialValues);
            getDataOrders(1, dayjs().startOf('day').format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), dayjs().endOf('day').format(DATE_FORMAT.YYYYMMDDHHMMSS_FORMAT), true)
        }
    }, [form])


    return (
        <div className='container-content'>

            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col span={7}>
                        <Form.Item
                            name={'keyword'}
                        >
                            <Input placeholder={'Order Id, Origin Id, name, sku '} />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item
                            name={'suppiler_id'}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Chọn nhà cung cấp'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={dataSuppliers?.map(item => ({ value: item?.id, label: item?.name }))}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item
                            name="status"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Chọn trạng thái'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={STATUS_ORDER.filter(status => status.type.includes(2))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name="delivery_status"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Shipping status'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={STATUS_SHIPPING}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name="store_name"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Chọn store'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={dataShops?.map((item, index) => ({ value: item?.name, label: `${index + 1}. ${item?.name}` }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name={'isHaveExportAt'}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Chọn loại'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={TYPE_EXPORT}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item
                            name={'typeTime'}
                            rules={requiredItem}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Loại thời gian'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={TYPE_TIME}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="date"
                        >
                            <RangePicker
                                showTime
                                allowClear={false}
                                style={{ width: '100%', height: 40 }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Space size="small">
                            <Tooltip title="Tìm kiếm">
                                <Button type="primary" htmlType="submit">
                                    <SearchOutlined />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Export CSV nháp">
                                <Button danger onClick={handleExportDraft} disabled={selectedRows.length > 0 ? false : true}>
                                    <ExportOutlined />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Export CSV lựa chọn">
                                <Button style={{ backgroundColor: 'yellowgreen' }} onClick={handleExportSelected} disabled={selectedRows.length > 0 ? false : true}>
                                    <CheckCircleOutlined style={{ color: 'white', fontWeight: '500', fontSize: 14 }} />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Export CSV all">
                                <Button style={{ background: '#ffde9f' }} onClick={handleExportAll}>
                                    <SendOutlined style={{ color: 'black', fontWeight: '500', fontSize: 14 }} />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Cập nhật thông tin hàng loạt">
                                <Button onClick={() => setShowModalSupplier(true)} disabled={selectedRows.length > 0 ? false : true}>
                                    <DoubleRightOutlined />
                                </Button>
                            </Tooltip>
                        </Space>
                    </Col>
                </Row>
                <Row>

                </Row>
            </Form>
            {
                selectedRows.length > 0
                    ?
                    <div style={{ marginBottom: 12 }}>
                        <span 
                            style={{ color: 'red', cursor: 'pointer'}} 
                            onClick={() => setShowModalSelected(true)}>
                            Selected {selectedRows.length} orders
                        </span>
                        <Tooltip title="Delete selected">
                            <Button type='link' disabled={selectedRows.length > 0 ? false : true} onClick={handleClearSelected}>
                                <CloseCircleOutlined style={{ color: 'red', fontWeight: '500', fontSize: 14 }} />
                            </Button>
                        </Tooltip>
                    </div> : null
            }
            <Table
                className='table-book custom-table'
                columns={columns}
                dataSource={data}
                rowKey={(record) => record?.id}
                pagination={false}
                bordered
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                rowClassName={() => 'custom-row'}
                scroll={{ x: 'max-content' }}
                // scroll={{ x: 'max-content', y: 600 }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            handleEditOrder(record);
                        }, // click row
                        onMouseEnter: event => {
                            event.currentTarget.style.cursor = 'pointer';
                        }, // hover row
                    };
                }}
            />
            <FooterTable
                totalRecord={totalRecords}
                totalPage={totalPage}
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
                onSelect={handleOnSelect}
                isShowPageSize={true}
                currentSize={currentPageSize}
                onSelectSize={handleOnSelectPageSize}
            >
            </FooterTable>
            <ModalOrderDetail
                visible={showModal}
                data={itemSelected}
                type={typeModal}
                title={titleModal}
                lstSupplier={dataSuppliers?.map(item => ({ value: item?.id, label: item?.name }))}
                onCancel={() => setShowModal(false)}
                onSave={handleSaveOrder}
                lstDepartment={dataDepartments?.map(item => ({ value: item?.id, label: item?.name }))}
                lstAction={dataAction}
                lstCategories={dataCategories}
                lstProvider={lstProvider}
            />
            <ModalAddOrder
                visible={showModalAddOrder}
                onCancel={() => setShowModalAddOrder(false)}
                onSave={handleSaveOrder}
                title={titleModal}
                lstSupplier={dataSuppliers?.map(item => ({ value: item?.id, label: item?.name }))}
                type={typeModal}
            />
            <ModalSelectSupplier
                lstSupplier={dataSuppliers?.map(item => ({ value: item?.id, label: item?.name }))}
                visible={showModalSupplier}
                onCancel={() => setShowModalSupplier(false)}
                onSave={handleSaveSupplier}
            />
            <ModalSelectedOrder 
                visible={showModalSelected}
                data={selectedRows}
                onCancel={() => setShowModalSelected(false)}
                onDelete={handleRemoveSelected}
            />
            <LoadingBase loading={loading} />
        </div>
    )
}
export default React.memo(ListExportSupplierPage);