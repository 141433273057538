import { CheckCircleOutlined, DownloadOutlined, ExclamationCircleOutlined, ImportOutlined, InfoCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Modal, Row, Space, Table, Tooltip, Upload, message } from "antd";
import React, { useState } from "react";
import * as XLSX from 'xlsx';
import '../../css/importexcel-page.css';
import LoadingBase from "../../components/LoadingBase";
import { useSelector } from "react-redux";
import { consoleLogUtil } from "../../utils/consoleLogUtil";
import { updateTrackingNumberFromImport, verifyDataImportTracking } from "../../services/apis";
import { exportToExcel } from "../../utils/funcHelper";
import { BASE_URL } from "../../settings/config";

const ImportTrackingPage = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [isShowButton, setIsShowButton] = useState(false);
    const [modal, contextHolder] = Modal.useModal();
    const [loadingImport, setLoadingImport] = useState(false);
    const [mess, setMessage] = useState('');
    const [isShowModalMessage, setIsShowModalMessage] = useState(false);
    const userReducer = useSelector(state => state?.userReducer?.data);
    const columns = [
        {
            title: 'ID',
            dataIndex: 'order_key',
            width: 150,
            key: 'order_key',
            render: (text, record, index) => <div>
                <a style={{ paddingLeft: 8, paddingRight: 8 }}>{text}</a>
            </div>,
        },
        {
            title: 'Origin ID',
            dataIndex: 'order_id',
            key: 'order_id',
            align: 'center',
            render: (text, record, index) => <div>
                <span style={{ paddingLeft: 8, paddingRight: 8 }}>{text}</span>
            </div>,
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            render: (text, record, index) => <div>
                <span style={{ paddingLeft: 8, paddingRight: 8 }}>{text}</span>
            </div>,
        },
        {
            title: 'Qty',
            width: 70,
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
        },
        {
            title: 'Provider code',
            dataIndex: 'provider_code',
            key: 'provider_code',
            width: 150,
            align: 'center',
            render: (text, record, index) => <div>
                <span style={{ paddingLeft: 8, paddingRight: 8 }}>{text}</span>
            </div>,
        },
        {
            title: 'Tracking number',
            dataIndex: 'tracking_number',
            key: 'tracking_number',
            width: 200,
            render: (text, record, index) => <div>
                <span style={{ paddingLeft: 8, paddingRight: 8 }}>{text}</span>
            </div>,
        }
        ,
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            render: (txt, record, index) => <span style={{ color: record?.isPass ? 'blue' : 'red', paddingLeft: 8, paddingRight: 8 }}>{record?.isPass ? <CheckCircleOutlined /> : txt}</span>
        }
    ]



    const confirm = () => {
        
        modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to update tracking number?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => handleSaveInfo()
        });
    };

    const handleDownloadFile = () => {
        exportToExcel(data, 'verify_tracking');
    }

    const handleDownloadTemplate = () => {
        const link = BASE_URL + 'files/template_import.xlsx';
        window.open(link, '_blank');
    }

    const handleSaveInfo = async () => {
        const payload = data.map(item => {
            return {
                order_key: item?.order_key,
                tracking_number: item?.tracking_number,
                provider_code: item?.provider_code,
                editTrackingBy: userReducer?.user_info?.id,
                order_id: item?.order_id,
            }
        });
        setLoading(true);
        const res = await updateTrackingNumberFromImport(payload);
        setLoading(false);
        if (res && res?.status === 1) {
            setMessage(res?.msg ?? 'Update tracking success');
            setIsShowModalMessage(true);
            setData([]);
            setIsShowButton(false);
        } else {
            message.warning(res?.msg ?? 'Something went wrong');
        }
    }
    const handleFileImport = async (dataImport) => {
        consoleLogUtil('dataImport', dataImport);
        const keys = ["order_key", "order_id", "sku", "quantity", "provider_code", "tracking_number"];
        // Duyệt qua các hàng để tạo mảng đối tượng
        const result = dataImport.filter((item, index) => index > 0 && item?.length > 0).map(row => {
            return row.reduce((obj, value, index) => {
                // Map mỗi giá trị trong hàng với key tương ứng
                obj[keys[index]] = value;
                return obj;
            }, {});
        });
        setLoading(true);
        const res = await verifyDataImportTracking(result);
        setLoading(false);
        if (res && res?.status === 1) {
            setData([...res?.data]);
            if (res?.data?.filter(item => !item?.isPass)?.length > 0) {
                setIsShowButton(false);
            } else {
                setIsShowButton(true);
            }
        } else {
            message.warning(res?.msg ?? 'Something went wrong');
        }

    }
    const props = {
        name: 'file',
        accept: '.xlsx,.xls',
        beforeUpload: (file) => {
            console.log('beforeUpload', file)
            setLoadingImport(true);
            let reader = new FileReader();
            reader.onload = function (e) {
                let data = e.target.result;
                let readedData = XLSX.read(data, { type: 'binary' });
                const wsname = readedData.SheetNames[0];
                const ws = readedData.Sheets[wsname];

                /* Convert array to json*/
                const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
                if (dataParse?.length > 1001) {
                    message.error("Do not exceed 1000 orders")
                    setLoadingImport(false);
                    return;
                } else if (dataParse?.length > 0) {
                    handleFileImport(dataParse);
                } else {
                    message.error("No orders found")
                }

                setLoadingImport(false);
            };
            reader.readAsBinaryString(file);
            return false;
        },
    };

    return (

        <div>
            {
                contextHolder
            }
            <Row className="row-button">
                <Upload {...props} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }}>
                    <Tooltip title="Max upload 1000 orders/file">
                        <Button 
                            type="default" 
                            size="middle" block danger 
                            icon={<ImportOutlined />} 
                            disabled={loadingImport} 
                            onClick={() => { }}
                            style={{ paddingLeft: 10, paddingRight: 10 }}
                        >
                            Import Excel
                        </Button>
                    </Tooltip>
                </Upload>
                <Space>
                    <Button
                        type="primary"
                        size="middle"
                        block icon={<SaveOutlined />}
                        disabled={loading || !isShowButton}
                        onClick={confirm}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                        Update tracking
                    </Button>
                    <Button
                        size="middle"
                        block icon={<DownloadOutlined />}
                        disabled={loading || data.length <= 0}
                        onClick={handleDownloadFile}
                        style={{ paddingLeft: 10, paddingRight: 10, background: !loading && data.length > 0 ?  'yellow' : null }}
                    >
                        Dowload file checked
                    </Button>
                    <Button
                        size="middle"
                        block icon={<DownloadOutlined />}
                        disabled={loading}
                        onClick={handleDownloadTemplate}
                        style={{ paddingLeft: 10, paddingRight: 10, background: 'green', color: 'white' }}
                    >
                        Dowload template
                    </Button>
                </Space>
                

            </Row>
            {
                data.length > 0 ?
                    <div>Total: {data.length} order</div> : null
            }

            <Table
                className='table-book custom-table'
                columns={columns}
                dataSource={data}
                style={{ marginTop: 20 }}
                rowClassName={() => 'custom-row'}
                scroll={{ x: 'max-content' }}
                pagination={{
                    showSizeChanger: true,
                    size: 'small'
                }}
                rowKey={(item) => item.id?.toString()}
            />
            <LoadingBase loading={loading} />
            <Modal
                open={isShowModalMessage}
                centered
                footer={[
                    <Button key="submit" type="primary" onClick={() => setIsShowModalMessage(false)}>
                        Close
                    </Button>
                ]}
                closable={false}
            >
                <div>
                    <p className="title-notify">
                        <InfoCircleOutlined style={{
                            color: 'blue',
                        }} /> Notify</p>

                    <span>{mess}</span>

                </div>
            </Modal>
        </div>
    )
}

export default React.memo(ImportTrackingPage);
